import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop/Shop";
import Footer from "./components/Footer/Footer";
import Product from "./pages/Product/Product";
import Error from "./pages/Error/Error";
import Ranking from "./pages/Ranking/Ranking";
import Market from "./pages/Market/Market";
import Verify from "./pages/Verify/Verify";
import MasterSetValue from "./pages/MasterSetValue/MasterSetValue";
import ScrollToTop from "./components/ScrollToTop";
import Maintenance from "./pages/Maintenance/Maintenance";
import Navbar from "./components/NavbarRest/NavbarRest";
import Forgot from "./pages/Forgot/Forgot";
// require("dotenv").config();
import axios from "axios";
import RealtimeAuction from "./pages/RealtimeAuction/RealtimeAuction";
import UnderValueBot from "./pages/UnderValueBot/UnderValueBot";
import UnderValueBot2 from "./pages/UnderValueBot2/UnderValueBot2";
import MarketTrend from "./pages/MarketTrend/MarketTrend";
import MarketIndex from "./pages/MarketIndex/MarketIndex";
axios.defaults.withCredentials = true;

function App() {
  const hasWindow = typeof window !== 'undefined';
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [userSession, setUserSession] = React.useState(null);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    return {
      width
    };
  }

  React.useEffect(() => {
    if (!userSession) {
      axios.post(
        "" +
        process.env.REACT_APP_BACKEND_URL +
        "api/user/check-session"
      ).then((res) => {
        if (res.data.error) {
          setUserSession(false);
          // console.log("User is not Logged In");
          // console.log(res);
        } else {
          setUserSession(true);
          // console.log("User is Logged In")
          // console.log(res.data.message);
        }
      }
      ).catch((err) => {
        console.log(err);
      });
    }
  }, [userSession])

  React.useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);


  function MarketCheck() {
    if (userSession !== null) {
      if (userSession) {
        return Market;
      } else {
        return Home;
      }
    }
  }

  function MasterCheck() {
    if (userSession !== null) {
      if (userSession) {
        return MasterSetValue;
      } else {
        return Home;
      }
    }
  }
  function RankingCheck() {
    if (userSession !== null) {
      if (userSession) {
        return Ranking;
      } else {
        return Home;
      }
    }
  }



  return (
    <>
      {/* {(windowDimensions && windowDimensions.width && windowDimensions.width >= 960) ? ( */}
      <Router>
        <ScrollToTop>
          <Navbar userSession={userSession} setUserSession={setUserSession} />
          <Switch>
            {/* <Route path="/" exact component={Home} />
            <Route path="/search" component={Shop} />
            <Route path="/product" component={Product} />
            <Route path="/market" component={MarketCheck()} />
            <Route path="/verify" component={Verify} />
            <Route path="/setvalue" component={MasterCheck()} />
            <Route path="/ranking" component={RankingCheck()} />
            <Route path="/reset-password" component={Forgot} />
            <Route path='/auction-monitor' component={RealtimeAuction} />
            <Route path='/undervalue-bot' component={UnderValueBot} />
            <Route path='/market-trend' component={MarketTrend} /> */}
            {/* <Route component={UnderValueBot} /> */}
            {/* <Route component={UnderValueBot2} /> */}
            <Route component={MarketIndex} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
      {/* ) : ( */}

      {/* For Maintenance Mode   ################################################################### */}
      {/* <Router>
            <ScrollToTop>
              <Switch>
                <Route component={Maintenance} />
              </Switch>
            </ScrollToTop>
          </Router>*/}
      {/* )} */}
    </>
  );
}

export default App;
