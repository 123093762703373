import React, { useState } from "react";
import "./MarketIndexChart.css";
import axios from "axios";
import CanvasJSReact from "../../assets/canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
axios.defaults.withCredentials = true

export default function MarketIndexChart({ data }) {
    const hasWindow = typeof window !== "undefined";
    const [time, setTime] = React.useState("ALL");
    const [timeData, setTimeData] = useState([]);
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );

    function toDate(dateStr) {
        const d = dateStr.split("T")[0];
        const [year, month, day] = d.split("-");
        const properDate = new Date(year, month - 1, day);
        return properDate;
    }

    async function GetTimeData() {
        let temp = [];
        if (time === "ALL") {
            temp = data;
        } else {
            let timespan;
            if (time === "3M") timespan = 92;
            else if (time === "6M") timespan = 183;
            else if (time === "1Y") timespan = 365;
            else if (time === "2Y") timespan = 730;
            let current = new Date();
            current.setDate(current.getDate() - timespan);
            let date;
            let month = "";
            let days = "";
            if (current.getMonth() < 9) {
                month = "0" + (current.getMonth() + 1);
            } else {
                month = current.getMonth() + 1;
            }
            if (current.getDate() < 10) {
                days = "0" + current.getDate();
            } else {
                days = current.getDate();
            }
            let year = current.getFullYear() + "";
            date = `${days}-${month}-${year}`;
            temp = data.filter(
                (item) =>
                    item.date.split("-").join("") >
                    date.split("-").reverse().join("")
            );
        }
        if (temp.length === 0) {
            setTimeData([]);
        } else {
            setTimeData(temp);
        }
    }
    React.useEffect(() => {
        GetTimeData();
    }, [data]);

    React.useEffect(() => {
        GetTimeData();
    }, [time]);

    const dataPointsPSA = () => {
        const dataPoints = [];
        timeData.map((item) => {
            dataPoints.push({
                x: toDate(item.date),
                y: item.psaTotal ? parseInt(item.psaTotal) : null,
            });
        });
        return dataPoints;
    }
    const dataPointsCGCMint = () => {
        const dataPoints = [];
        timeData.map((item) => {
            dataPoints.push({
                x: toDate(item.date),
                y: item.cgcGMTotal ? parseInt(item.cgcGMTotal) : null,
            });
        });
        return dataPoints;
    }
    const dataPointsCGCPristine = () => {
        const dataPoints = [];
        timeData.map((item) => {
            dataPoints.push({
                x: toDate(item.date),
                y: item.cgcPRTotal ? parseInt(item.cgcPRTotal) : null,
            });
        });
        return dataPoints;
    }

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        return {
            width,
        };
    }

    React.useEffect(() => {
        if (hasWindow && window.location.pathname === "/") {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [hasWindow]);

    function getThickness() {
        if (
            windowDimensions &&
            windowDimensions.width &&
            windowDimensions.width < 800
        )
            return 1;
        else return 2;
    }

    const colors = CanvasJS.addColorSet("marketIndexColorSet", ["#F59E0B", "#D10000", "#10B981"]);
    const options = {
        colorSet: "marketIndexColorSet",
        animationEnabled: true,
        animationDuration: 2000,
        backgroundColor: "#0E1628",
        theme: "none",
        height: 500,
        axisX: {
            display: true,
            margin: 15,
            scaleBreaks: {
                autoCalculate: true,
            },
            interval: time === '3M' ? 15 : time === '6M' ? 1 : time === '1Y' ? 2 : time === '2Y' ? 3 : 4,
            intervalType: time === '3M' ? "day" : "month",
            labelFontColor: "#fff",
            tickColor: "#08131F",
            lineColor: "#365C9E",
            valueFormatString: time === '3M' ? "DD MMM YYYY" : "MMM YYYY",
        },
        axisY: {
            scaleBreaks: {
                autoCalculate: true,
            },
            labelFontColor: "#fff",
            includesZero: false,
            valueFormatString: "#,###,###.##",
            tickColor: "#08131F",
            lineColor: "#0E1628",
        },
        toolTip: {
            content:
                "{name}:<br/>Date: <strong>{x}</strong><br/>PMI 500 Index: <strong>{y}</strong>",
        },
        legend: {
            fontColor: "#fff",
            markerMargin: 10,
            fontFamily: "Roboto",
            fontWeight: "light",
            itemWidth: 300,
            itemclick: function (e) {
                if (
                    typeof e.dataSeries.visible === "undefined" ||
                    e.dataSeries.visible
                ) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            },
        },
        data: [
            {
                lineThickness: getThickness(),
                type: "spline",
                markerType: "circle",
                markerSize: 0,
                showInLegend: true,
                name: "PMI 500 Index (PSA)",
                dataPoints: dataPointsPSA(),
            },
            {
                lineThickness: getThickness(),
                type: "spline",
                markerType: "circle",
                markerSize: 0,
                showInLegend: true,
                name: "PMI 500 Index (CGC Gem Mint 10)",
                dataPoints: dataPointsCGCMint(),
            },
            {
                lineThickness: getThickness(),
                type: "spline",
                markerType: "circle",
                markerSize: 0,
                showInLegend: true,
                name: "PMI 500 Index (CGC Pristine 10)",
                dataPoints: dataPointsCGCPristine(),
            },
        ],
    };
    const Moboptions = {
        colorSet: "marketIndexColorSet",
        animationEnabled: true,
        animationDuration: 2000,
        height: 220,
        backgroundColor: "#08131f",
        theme: "none",
        axisX: {
            display: true,
            margin: 15,
            scaleBreaks: {
                autoCalculate: true,
            },
            interval: time === '3M' ? 15 : time === '6M' ? 1 : time === '1Y' ? 2 : time === '2Y' ? 3 : 4,
            intervalType: time === '3M' ? "day" : "month",
            labelFontColor: "#fff",
            tickColor: "#08131F",
            lineColor: "#365C9E",
            valueFormatString: time === '3M' ? "DD MMM YYYY" : "MMM YYYY",
        },
        axisY: {
            scaleBreaks: {
                autoCalculate: true,
            },
            labelFontColor: "#fff",
            includesZero: false,
            valueFormatString: "#,###,###.##",
            tickColor: "#08131F",
            lineColor: "#0E1628",
        },
        toolTip: {
            content:
                "{name}:<br/>Date: <strong>{x}</strong><br/>PMI 500 Index: <strong>{y}</strong>",
        },
        legend: {
            cursor: "pointer",
            fontColor: "#fff",
            verticalAlign: "top",
            fontFamily: "Roboto",
            fontWeight: "light",
            itemWidth: 200,
            itemclick: function (e) {
                if (
                    typeof e.dataSeries.visible === "undefined" ||
                    e.dataSeries.visible
                ) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            },
        },
        data: [
            {
                lineThickness: getThickness(),
                type: "spline",
                markerType: "circle",
                markerSize: 0,
                showInLegend: true,
                name: "PMI 500 Index (PSA)",
                dataPoints: dataPointsPSA(),
            },
            {
                lineThickness: getThickness(),
                type: "spline",
                markerType: "circle",
                markerSize: 0,
                showInLegend: true,
                name: "PMI 500 Index (CGC Gem Mint 10)",
                dataPoints: dataPointsCGCMint(),
            },
            {
                lineThickness: getThickness(),
                type: "spline",
                markerType: "circle",
                markerSize: 0,
                showInLegend: true,
                name: "PMI 500 Index (CGC Pristine 10)",
                dataPoints: dataPointsCGCPristine(),
            },
        ],
    };
    return (
        <div className="marketIndexChart">
            <div className="marketIndexChart__time__buttons">
                <div className={time === '3M' ? "marketIndexChart__time__button__selected" : "marketIndexChart__time__button"} onClick={() => setTime('3M')}>3M</div>
                <div className={time === '6M' ? "marketIndexChart__time__button__selected" : "marketIndexChart__time__button"} onClick={() => setTime('6M')}>6M</div>
                <div className={time === '1Y' ? "marketIndexChart__time__button__selected" : "marketIndexChart__time__button"} onClick={() => setTime('1Y')}>1Y</div>
                <div className={time === '2Y' ? "marketIndexChart__time__button__selected" : "marketIndexChart__time__button"} onClick={() => setTime('2Y')}>2Y</div>
                <div className={time === 'ALL' ? "marketIndexChart__time__button__selected" : "marketIndexChart__time__button"} onClick={() => setTime('ALL')}>ALL</div>
            </div>
            {
                windowDimensions &&
                    windowDimensions.width &&
                    windowDimensions.width > 650 ? (
                    <CanvasJSChart
                        options={options}
                    />
                ) : (
                    <CanvasJSChart
                        options={Moboptions}
                    />
                )
            }
        </div>
    );
}
