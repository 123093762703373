import React from "react";
import "./BreadCrumb.css"
import { useHistory } from "react-router-dom";

export default function BreadCrumb() {
    const history = useHistory();
    const [BC, setBC] = React.useState(null);
    const [Comp, setComp] = React.useState(null);

    React.useEffect(() => {
        setBC(sessionStorage.getItem("bread"))
    }, [sessionStorage.getItem("bread")])

    React.useEffect(() => {
        if (window.location.pathname === "/search") {
            setComp(<span>Search</span>);
        }
        else if (window.location.pathname === "/market-index"){
            setComp(<span>Market Index</span>);
        }
        else if (window.location.pathname === "/market") {
            setComp(<span>Market Research Tool</span>);
        }
        else if (window.location.pathname === "/auction-monitor") {
            setComp(<span>Auction Monitor</span>);
        }
        else if (window.location.pathname === "/undervalue-bot") {
            setComp(<span>Undervalue Bot</span>);
        }
        else if (window.location.pathname === "/market-trend") {
            setComp(<span>Market Trend</span>);
        }
        else if (window.location.pathname === "/setvalue") {
            setComp(<span>Master Set Value</span>);
        }
        else if (window.location.pathname === "/ranking") {
            setComp(<span>Market Cap Ranking</span>);
        }
        else {
            if (BC === "shop") {
                if (sessionStorage.getItem("searchLink")) {
                    setComp(<>
                        <span onClick={() => { history.push(sessionStorage.getItem("searchLink")) }}>Search</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                } else {
                    setComp(<>
                        <span onClick={() => { history.push("/search") }}>Search</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                }
            } else if (BC === "home") {
                setComp(<>
                    <span>Product</span>
                </>);
            }
            else if (BC === "setvalue") {
                setComp(<>
                    <span onClick={() => { history.push("/setvalue") }}>Master Set Value</span>
                    <span>{">"}</span>
                    <span>Product</span>
                </>);
            }
            else if (BC === "trend") {
                setComp(<>
                    <span onClick={() => { history.push("/market-trend") }}>Market Trend</span>
                    <span>{">"}</span>
                    <span>Product</span>
                </>);
            }
            else if (BC === "ranking") {
                if (sessionStorage.getItem("searchLink")) {
                    setComp(<>
                        <span onClick={() => { history.push(sessionStorage.getItem("searchLink")) }}>Market Cap Ranking</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                } else {
                    setComp(<>
                        <span onClick={() => { history.push("/ranking") }}>Market Cap Ranking</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                }
            }
            else {
                setComp(<>
                    <span>Product</span>
                </>);
            }
        }
    }, [BC])

    return (
        <div className="BreadCrumb">
            <div className="BreadCrumb__Text" onClick={() => { history.push("/") }}> Home </div>
            <div className="BreadCrumb__Text" >  {(Comp) ? ">" : null} </div>
            <div className="BreadCrumb__Text">{Comp}</div>
        </div>
    )
}