import React from "react";
import "./MarketIndex.css";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { formatDate } from "../../assets/canvasjs.min";
import axios from "axios";
import { useHistory } from "react-router";
import MarketIndexChart from "../../components/MarketIndexChart/MarketIndexChart";
import { Link } from "react-router-dom";
import MarketIndexChartScaled from "../../components/MarketIndexChartScaled/MarketIndexChartScaled";
import MarketIndexChartLang from "../../components/MarketIndexChartLang/MarketIndexChartLang";
import MarketIndexChartLangScaled from "../../components/MarketIndexChartLangScaled/MarketIndexChartLangScaled";

function MarketIndex() {
    const hasWindow = typeof window !== "undefined";
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );
    const history = useHistory();
    const [activeTab, setActiveTab] = React.useState(null);
    const [selection, setSelection] = React.useState(0);
    const [langSelect, setLangSelect] = React.useState(0);
    const [scalingFlag, setScalingFlag] = React.useState(0);
    const [PMIData, setPMIData] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [graphData, setGraphData] = React.useState([]);
    const [graphDataScaled, setGraphDataScaled] = React.useState([]);
    const [graphDataLang, setGraphDataLang] = React.useState([]);
    const [graphDataLangScaled, setGraphDataLangScaled] = React.useState([]);
    const [listingDetails, setListingDetails] = React.useState([]);

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        return {
            width,
        };
    }

    React.useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [hasWindow]);

    React.useEffect(() => {
        history.push("/market-index");
    }, []);

    React.useEffect(() => {
        axios
            .get(
                "" + process.env.REACT_APP_BACKEND_URL + `api/pmi/getPmiData?langFlag=${selection}&scalingFlag=${scalingFlag}`,
            )
            .then((res) => {
                if (res.data.error) {
                    setError(true)
                } else {
                    console.log(res.data.data)
                    setPMIData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true)
            });
    }, [selection, scalingFlag]);

    React.useEffect(() => {
        axios
            .get(
                "" + process.env.REACT_APP_BACKEND_URL + `api/pmi/getGraphData?langFlag=${selection}&scalingFlag=${scalingFlag}`,
            )
            .then((res) => {
                if (res.data.error) {
                    setError(true)
                } else {
                    // console.log(res.data.data)
                    if (selection === 0) {
                        if (scalingFlag === 0) {
                            setGraphData(res.data.data);
                        }
                        else if (scalingFlag === 1) {
                            setGraphDataScaled(res.data.data);
                        }
                    }
                    else if (selection === 1) {
                        if (scalingFlag === 0) {
                            setGraphDataLang(res.data.data);
                        }
                        else if (scalingFlag === 1) {
                            setGraphDataLangScaled(res.data.data);
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true)
            });
    }, [scalingFlag, selection]);

    React.useEffect(() => {
        axios
            .get(
                "" + process.env.REACT_APP_BACKEND_URL + `api/pmi/getListingDetails?langFlag=${selection}&langSelected=${langSelect === 0 ? 'en' : 'jp'}`,
            )
            .then((res) => {
                if (res.data.error) {
                    setError(true)
                } else {
                    // console.log(res.data.data)
                    setListingDetails(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true)
            });
    }, [selection, langSelect]);


    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber === activeTab ? null : tabNumber);
    };

    const handleSelection = (tab) => {
        setSelection(tab === selection ? tab : tab);
        setScalingFlag(0);
    }

    const handleLangSelection = (tab) => {
        setLangSelect(tab === langSelect ? tab : tab);
    }

    function calculateChange(prev, current) {
        let change = ((prev - current) / prev) * -100;
        return change.toFixed(2);
    }

    return (

        <div className="market__index__page">
            <div className="market__index__container">
                <div className="mi__container__top">
                    <div className="mi__content__top__left">
                        <BreadCrumb />
                        <div className="mi__content__top__heading">
                            <div className="mi__content__top__heading__sub__title">
                                TCGFISH Graded Card Market Index
                            </div>
                            <div className="mi__content__top__heading__title">
                                Pokémon Market Index 500 (PMI 500)
                            </div>
                        </div>
                    </div>
                    <div className="mi__content__top__right">
                        <div className="mi__content__top__right__update">
                            <div className="mi__content__top__right__update__time">
                                Last Update:{" "}
                                {formatDate(
                                    PMIData?.latest?.date,
                                    "DD-MMM-YYYY"
                                )}
                            </div>
                        </div>
                        <div className="mi__content__top__right__discord">
                            <Link className="mi__content__top__right__discord__btn" onClick={() => window.open("https://discord.gg/YCgc779Syz", "_blank")}> <i style={{ color: "#FFFFFF" }} className="fab fa-discord mi__content__top__right__discord__btn__icon "></i><div>Join Our Discord</div> </Link>
                        </div>
                    </div>
                </div>
                <div className="mi__container__bottom">
                    <div className="mi__container__bottom__section">
                        <div className="mi__container__bottom__section__text">
                            Similar to the S&P 500 Index, the subsequent index provides a comprehensive reflection of the professionally graded collectible trading cards, specifically the Pokémon category (the Sports Card index can use the same methodology). (Simple terms, we have 500 cards in one big basket, and we track the performance of the whole basket.)
                        </div>
                        <div className="mi__container__bottom__section__text">
                            TCGFish is pleased to announce this market index and we are confident this will become a foundational tool in the collectibles industry. To foster transparency, we outline the methodologies employed for the index in the documentation below. Should you have any recommendations or feedback, kindly connect with us on our Discord or reach out at support@tcgfish.com
                        </div>
                    </div>
                    <div className="mi__container__bottom__section"></div>
                    <div className="mi__container__bottom__section__header">
                        {activeTab === null ?
                            `PMI 500 Documentation (Click Below Documentations To Expand Details)`
                            :
                            `PMI 500 Documentation (Click Below Documentations Again To Hide Details)`
                        }
                    </div>
                    <div className="mi__container__bottom__button">
                        <div className={activeTab === 1 ? "mi__container__bottom__button__btn__selected" : "mi__container__bottom__button__btn"} onClick={() => handleTabClick(1)}><i className="mi__container__bottom__button__btn__icon fa fa-file-invoice"></i>Pokémon Market Index 500</div>
                        <div className={activeTab === 2 ? "mi__container__bottom__button__btn__selected" : "mi__container__bottom__button__btn"} onClick={() => handleTabClick(2)}><i className="mi__container__bottom__button__btn__icon fa fa-file-invoice"></i>CGC Pokémon Market Index 500</div>
                        <div className={activeTab === 3 ? "mi__container__bottom__button__btn__selected" : "mi__container__bottom__button__btn"} onClick={() => handleTabClick(3)}><i className="mi__container__bottom__button__btn__icon fa fa-file-invoice"></i>English/Japanese<br />Pokémon Market Index 500</div>
                    </div>
                    {activeTab === 1 ?
                        <>
                            <div className="mi__container__bottom__section">
                                <div className="mi__container__bottom__section__heading">
                                    The Selection and Computation Process for the PMI 500 index
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    a. <strong>Algorithmic Selection:</strong> Contrary to other indices in the collectible market, our card selection is purely algorithm-driven.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    b. <strong>Tenure on Market:</strong> A card must have been available in the market for a minimum of two years. For instance, even if a 2023 Pikachu promo card garners significant popularity, it will not be considered for inclusion in the index due to its potential high volatility.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    c. <strong>Market Capitalization Criterion:</strong> A card must possess a minimum market capitalization of $35k.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    d. <strong>Public Float Criterion:</strong> A card should have a graded population of at least 200, indicative of the number of possible cards in circulation.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    e. <strong>Liquidity Criterion:</strong>
                                    <div className="mi__container__bottom__section__text__sub">
                                        <div className="mi__container__bottom__section__text__sub__i">1 -</div>
                                        A English language card must demonstrate an average sales frequency of at least 0.15 sales per day (equivalent to 1 sale per week). For example, while the PSA 10 Base Set First Edition Charizard is arguably the most iconic card, it is excluded due to its low liquidity. However, the more liquid (PSA/CGC) (8/7/6) version might be incorporated based on algorithmic selection.
                                    </div>
                                    <div className="mi__container__bottom__section__text__sub">
                                        <div className="mi__container__bottom__section__text__sub__i">2 -</div>
                                        Japanese card requires a frequency of 0.05 sales per day. Japanese cards have slightly lower liquidity requirements than English cards. It's important to note that our sales data originates from US market platforms. While a substantial number of Japanese cards are favored in the Asian market, their lack in US sales can lead to exclusions based on liquidity prerequisites (For instance, the pre-2020 Index. This is also notably evident among the high-end modern cards featuring Trainer Characters like Lillie and Marnie). However, as the appreciation of Japanese cards grows in the US, its total sales volume has increased significantly, contributing to a more accurate Japanese card market index.
                                    </div>
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    f. <strong>Priority Selection:</strong> If more than 500 cards match the criteria, we will select the top 500 cards based on the liquidity measurements.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    g. <strong>Selection of Card Grade:</strong> Upon the selection of a card, the grade with the highest liquidity will be chosen to represent the card. For example, in the case of the Unlimited Base Set Charizard card, the PSA 8 grade is identified as the most liquid. Once the PSA 8 grade is selected, other grades will not be considered for selection. Every card within the Index will be exclusively represented by its most liquid grade, which also needs to fulfill the Liquidity Criterion(e) outlined above.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    h. <strong>Data Utilization:</strong> This Index used PSA cards sales data, we also introduced an identical index that uses CGC card prices (CGC Pokémon Market Index 500).
                                </div>
                            </div>
                            <div className="mi__container__bottom__section">
                                <div className="mi__container__bottom__section__text">
                                    <strong>Additional Notes:</strong><br /><br />
                                    In alignment with the principles of the S&P 500, the PMI 500 index is inherently dynamic, with a periodic inclusion and exclusion of cards based on specific criteria. This periodic adjustment is referred to as the "rebalancing process." Within this process, cards gaining prominence are incorporated, while those diminishing in popularity are phased out. The PMI 500 index undergoes this rebalancing biannually, specifically on January 31st and July 31st.
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    In contrast to the stock market, where leadership positions among industry leaders are relatively stable, the card composition within the PMI index may experience more considerable shifts. Nevertheless, the rebalancing process adheres to the following stringent guidelines:
                                </div>
                            </div>
                            <div className="mi__container__bottom__section">
                                <div className="mi__container__bottom__section__text">
                                    a. <strong>Card Limitation for Rebalancing:</strong> During each rebalancing phase, there is a cap of 25%, implying a maximum alteration of 125 cards within the PMI 500 Index.
                                    <div className="mi__container__bottom__section__text__sub">- <strong>Historical Rebalancing Data:</strong>
                                        <div className="mi__container__bottom__section__text__sub" style={{ paddingLeft: '3%', paddingTop: "5px" }}>- 2020: 15 cards were adjusted.
                                            <br />- 2021: 121 cards were adjusted. (Index self-adjusted to include more modern cards.)
                                            <br />- 2022: 81 cards were adjusted.
                                            <br />- 2023: 66 cards were adjusted.
                                        </div>
                                    </div>
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    b. <strong>Continuity and the 'Base Divider':</strong> {`To ensure the consistent representation of the index, each rebalancing activity necessitates an adjustment to the 'Base Divider'. For the PMI 500, this divider has an upper limit of 2.5 (even though the value usually sits within <=1.5). It's crucial to understand that this value is specific to our index and may vary for others.`}
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    c. <strong>Weights Allocation:</strong> There are 2 ways to calculate the weights.
                                    <div className="mi__container__bottom__section__text__sub">
                                        <div className="mi__container__bottom__section__text__sub__i"><strong>1 -</strong></div>
                                        <strong>By Card Market Price:</strong> Contrary to the S&P 500 which employs market capitalization to determine portfolio weights, the PMI 500 can use the card's price for this weight calculation, in general, the higher market value of the card, the more important of the card, which provides a higher weight.
                                    </div>
                                    <div className="mi__container__bottom__section__text__sub">
                                        <div className="mi__container__bottom__section__text__sub__i"><strong>2 -</strong></div>
                                        <strong>By total sales value:</strong> To illustrate, consider a PSA 8 card with a current market price of $10 and a total sales volume of 50 units, leading to a total sales value of $500. Proceeding with this methodology, we aggregate the sales values across all cards and determine the respective weights accordingly. The benefit of using total sales value (similar to market cap in stocks) is that, despite its lack of direct linkage to liquidity measurements, the total sales value ranking exhibits a substantial correlation with the liquidity ranking, in other words, higher total sales value rank tends to have higher liquidity rank (both in Pearson Correlation and Spearman Correlation), In contrast with the previous method (1), this method provides a more comprehensive view by incorporating liquidity considerations.
                                    </div>
                                    <div className="mi__container__bottom__section__text__sub">
                                        <div className="mi__container__bottom__section__text__sub__i"><strong>3 -</strong></div>
                                        We believe both (1) (2) are good, but (2) more robust.
                                    </div>
                                </div>
                                <div className="mi__container__bottom__section__text">
                                    d. <strong>Maximum Weights Allocation:</strong> Individual card is subject to a weight ceiling of 5%. Should a card's weight exceed this threshold, it will be capped at 5%, and the excess weight will be proportionally redistributed across the remaining portfolio.
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                    {activeTab === 2 ?
                        <div className="mi__container__bottom__section">
                            <div className="mi__container__bottom__section__heading">
                                The CGC Market Index and its Relationship with PMI 500 Index
                            </div>
                            <div className="mi__container__bottom__section__text">
                                The CGC Market Index is a comprehensive tool tailored to measure the market fluctuations based on CGC card market prices. While it mirrors the objective of the PMI 500 Index, which monitors market trends, the distinction lies in the sales prices extracted from different grading companies.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                Consider this analogy: In the stock market, both TSLA and APPL are constituents of the S&P 500 Index and are exclusively traded on NASDAQ. Drawing a parallel to the collectible card industry, imagine if APPL and TSLA, representing graded cards, could also be traded on an alternative exchange, termed NASDAQ_v2. Given that prices might differ between these exchanges, our aim is to compute the same index based on the stock prices from this alternative exchange.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                These indices (PMI 500 and the CGC Market Index) not only provide insights into the market's directional movement but also highlights the distinctions. By showing the two indices together, it prompts the question: Is the PSA market more dynamic, or does the CGC market exhibit greater stability? From an investment standpoint, these are great questions.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>Clarifications on Key Components:</strong>
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>1.Portfolio Selection:</strong> The CGC Market Index uses identical cards to the PMI 500 Index. For instance, if the latter encompasses a PSA 8 Unlimited Base Set Charizard card, it is swapped with its CGC 8 counterpart. Similarly, a PSA 10 card is substituted with either CGC Gem Mint 10 or CGC Pristine 10, in line with the two versions of the CGC index, the CGC Market Index (Gem Mint 10 Version/Pristine 10 Version).
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>2.Weight Allocation:</strong> Portfolio weight allocation is consistent with the original PMI 500, derived from PSA market prices. Given PSA's market share of roughly 80%, it is more  precise to adhere to the PMI 500 portfolio weights.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>3.Base Divider and Continuity:</strong> The 'Base Divider' in the CGC Market Index varies from the PMI 500 Index, ensuring uniformity throughout every rebalancing phase.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>4.Gem Mint 10, Pristine 10, and Perfect 10:</strong>
                                <div className="mi__container__bottom__section__text__sub" style={{ paddingTop: '5px' }}>
                                    <div className="mi__container__bottom__section__text__sub__i" style={{ paddingTop: '5px' }}>1 -</div>
                                    CGC Perfect 10 data points are excluded from all computations. Due to their limited availability and elevated value, these cards do not genuinely represent the Graded 10 market value. (A Perfect 10 can reach $100 while a Pristine 10 is $25-$30.)
                                </div>
                                <div className="mi__container__bottom__section__text__sub" style={{ paddingTop: '5px' }}>
                                    <div className="mi__container__bottom__section__text__sub__i" style={{ paddingTop: '5px' }}>2 -</div>
                                    The new grading standards equate old CGC Gem Mint 9.5 with the new CGC Gem Mint 10. This allows for the availability of CGC Gem Mint 10 prices as of November 1, 2020.
                                </div>
                                <div className="mi__container__bottom__section__text__sub" style={{ paddingTop: '5px' }}>
                                    <div className="mi__container__bottom__section__text__sub__i" style={{ paddingTop: '5px' }}>3 -</div>
                                    Both the Old CGC Pristine 10 and the new CGC Pristine 10 fall under the same grading category, and they are treated equivalently. There are no modifications in this regard.
                                </div>
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>Additional notes on CGC Market Index's Inception Date of January 1, 2021:</strong>
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>- Reason for Choosing this Date:</strong> Although CGC initiated card grading in June 2020, the preliminary months lacked ample sales data to derive meaningful conclusions. To ensure the accuracy of market movement representation, we established a six-month buffering period (2020-June to 2020-Dec), which resulted in the selection of January 1, 2021, as the commencement date.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>- Option to align the CGC Index with PMI 500 Index's Starting Point:</strong> For a more coherent comparison of index trajectories, the CGC index can be scaled such that its initial value is the same as the concurrent value of the PSA index. This adjustment, akin to data normalization, provides a clear view of how each index evolves over time. This allows us to conclude which index portfolio outperforms the other, identify the more volatile one, and determine which remains more stable.
                            </div>
                        </div>
                        : null
                    }
                    {activeTab === 3 ?
                        <div className="mi__container__bottom__section">
                            <div className="mi__container__bottom__section__heading">
                                English and Japanese Market Indices
                            </div>
                            <div className="mi__container__bottom__section__text">
                                The English Market Index is specifically curated from cards available in the English language, while its Japanese Market Index focuses on Japanese cards. Both index uses the same selection and calculation standard as the PMI 500 Index.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                The language constraints mean that each index will feature a distinct set of cards compared to the main PMI 500 index.
                            </div>
                            <div className="mi__container__bottom__section__text">
                                <strong>Additional Note:</strong>
                            </div>
                            <div className="mi__container__bottom__section__text" style={{ paddingLeft: '3%', width: '97%' }}>
                                The Japanese Market Index currently contains 500 cards. However, prior to January 1, 2023, the index encapsulates less than 500 cards. However, for naming uniformity, we'll continue to use the “Japanese market index 500".
                                <div className="mi__container__bottom__section__text__sub">
                                    <strong>- Historical Japanese Market Index Card Numbers:</strong>
                                    <div className="mi__container__bottom__section__text__sub" style={{ paddingLeft: '3%', width: '97%', paddingTop: "5px" }}>
                                        - 2020: 182 cards included.<br />
                                        - 2021: 329 cards included.<br />
                                        - 2022: 393 cards included.<br />
                                        - 2023: 500 cards included.
                                    </div>
                                </div>
                            </div>
                            <div className="mi__container__bottom__section__text" style={{ paddingLeft: '3%', width: '97%' }}>
                                It's important to note that our sales data originates from US market platforms. While a substantial number of Japanese cards are favored in the Asian market, their lack in US sales can lead to exclusions based on liquidity prerequisites (For instance, the pre-2020 Index. This is also notably evident among the high-end modern cards featuring Trainer Characters like Lillie and Marnie). However, as the appreciation of Japanese cards grows in the US, its total sales volume has increased significantly, contributing to a more accurate Japanese card market index.
                            </div>
                            <div className="mi__container__bottom__section__text" style={{ paddingLeft: '3%', width: '97%' }}>
                                <strong>Option to align the English/Japanese Index with PMI 500 Index's Starting Point:</strong> For a more coherent comparison of index trajectories, both English/Japanese index can be scaled such that its initial value is the same as the concurrent value of the PMI index. This adjustment, akin to data normalization, provides a clear view of how each index evolves over time. This allows us to conclude which index portfolio outperforms the other, identify the more volatile one, and determine which remains more stable.
                            </div>

                        </div>
                        : null
                    }
                </div>
            </div>
            <div className="mi__container__content">
                <div className="mi__container__content__chart">
                    <div className="mi__container__content__chart__heading__selection">
                        <div className={selection === 0 ? "mi__container__content__chart__heading__selected" : "mi__container__content__chart__heading__select"} onClick={() => handleSelection(0)}>
                            PMI 500 Index
                        </div>
                        <div className={selection === 1 ? "mi__container__content__chart__heading__selected" : "mi__container__content__chart__heading__select"} onClick={() => handleSelection(1)}>
                            English/Japanese PMI 500 Index
                        </div>
                    </div>
                    {selection === 0 ?
                        <div className="mi__container__content__chart__details__section">
                            <div className="mi__container__content__chart__details">
                                <div className="mi__container__content__chart__heading">
                                    PMI 500 Index (PSA) Version
                                </div>
                                <div className="mi__container__content__chart__details__box">
                                    <div className="mi__container__content__chart__details__box__title">As of {PMIData && PMIData.latest.date ? formatDate(PMIData.latest.date, "DD-MMM-YYYY") : "---"}</div>
                                    <div className="mi__container__content__chart__details__box__value">{PMIData && PMIData.latest.psaTotal ? PMIData.latest.psaTotal.toFixed(1) : '---'}</div>
                                    <div className="mi__container__content__chart__details__box__divider"></div>
                                    <div className="mi__container__content__chart__details__box__year__changes">
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.yearBack?.psaTotal, PMIData?.latest?.psaTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.psaTotal ? `${calculateChange(PMIData?.yearBack?.psaTotal, PMIData?.latest?.psaTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">1 YR RETURN</div>
                                        </div>
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.twoYearBack?.psaTotal, PMIData?.latest?.psaTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.psaTotal ? `${calculateChange(PMIData?.twoYearBack?.psaTotal, PMIData?.latest?.psaTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">2 YR RETURN</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mi__container__content__chart__details__divider"></div>
                            <div className="mi__container__content__chart__details">
                                <div className="mi__container__content__chart__heading">
                                    PMI 500 Index (CGC Gem Mint 10 Version)
                                </div>
                                <div className="mi__container__content__chart__details__box">
                                    <div className="mi__container__content__chart__details__box__title">As of {PMIData && PMIData.latest.date ? formatDate(PMIData.latest.date, "DD-MMM-YYYY") : "---"}</div>
                                    <div className="mi__container__content__chart__details__box__value">{PMIData && PMIData.latest.cgcGMTotal ? PMIData.latest.cgcGMTotal.toFixed(1) : '---'}</div>
                                    <div className="mi__container__content__chart__details__box__divider"></div>
                                    <div className="mi__container__content__chart__details__box__year__changes">
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.yearBack?.cgcGMTotal, PMIData?.latest?.cgcGMTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.cgcGMTotal ? `${calculateChange(PMIData?.yearBack?.cgcGMTotal, PMIData?.latest?.cgcGMTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">1 YR RETURN</div>
                                        </div>
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.twoYearBack?.cgcGMTotal, PMIData?.latest?.cgcGMTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.cgcGMTotal ? `${calculateChange(PMIData?.twoYearBack?.cgcGMTotal, PMIData?.latest?.cgcGMTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">2 YR RETURN</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mi__container__content__chart__details">
                                <div className="mi__container__content__chart__heading">
                                    PMI 500 Index (CGC Pristine 10 Version)
                                </div>
                                <div className="mi__container__content__chart__details__box">
                                    <div className="mi__container__content__chart__details__box__title">As of {PMIData && PMIData.latest.date ? formatDate(PMIData.latest.date, "DD-MMM-YYYY") : "---"}</div>
                                    <div className="mi__container__content__chart__details__box__value">{PMIData && PMIData.latest.cgcPRTotal ? PMIData.latest.cgcPRTotal.toFixed(1) : '---'}</div>
                                    <div className="mi__container__content__chart__details__box__divider"></div>
                                    <div className="mi__container__content__chart__details__box__year__changes">
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.yearBack?.cgcPRTotal, PMIData?.latest?.cgcPRTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.cgcPRTotal ? `${calculateChange(PMIData?.yearBack?.cgcPRTotal, PMIData?.latest?.cgcPRTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">1 YR RETURN</div>
                                        </div>
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.twoYearBack?.cgcPRTotal, PMIData?.latest?.cgcPRTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.cgcPRTotal ? `${calculateChange(PMIData?.twoYearBack?.cgcPRTotal, PMIData?.latest?.cgcPRTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">2 YR RETURN</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="mi__container__content__chart__details__section">
                            <div className="mi__container__content__chart__details">
                                <div className="mi__container__content__chart__heading">
                                    PMI 500 Index (PSA) Version
                                </div>
                                <div className="mi__container__content__chart__details__box">
                                    <div className="mi__container__content__chart__details__box__title">As of {PMIData && PMIData.latest.date ? formatDate(PMIData.latest.date, "DD-MMM-YYYY") : "---"}</div>
                                    <div className="mi__container__content__chart__details__box__value">{PMIData && PMIData.latest.psaTotal ? PMIData.latest.psaTotal.toFixed(1) : '---'}</div>
                                    <div className="mi__container__content__chart__details__box__divider"></div>
                                    <div className="mi__container__content__chart__details__box__year__changes">
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.yearBack?.psaTotal, PMIData?.latest?.psaTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.psaTotal ? `${calculateChange(PMIData?.yearBack?.psaTotal, PMIData?.latest?.psaTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">1 YR RETURN</div>
                                        </div>
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.twoYearBack?.psaTotal, PMIData?.latest?.psaTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.psaTotal ? `${calculateChange(PMIData?.twoYearBack?.psaTotal, PMIData?.latest?.psaTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">2 YR RETURN</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mi__container__content__chart__details__divider"></div>
                            <div className="mi__container__content__chart__details">
                                <div className="mi__container__content__chart__heading">
                                    English Market PMI 500 Index
                                </div>
                                <div className="mi__container__content__chart__details__box">
                                    <div className="mi__container__content__chart__details__box__title">As of {PMIData && PMIData.latest.date ? formatDate(PMIData.latest.date, "DD-MMM-YYYY") : "---"}</div>
                                    <div className="mi__container__content__chart__details__box__value">{PMIData && PMIData.latest.engTotal ? PMIData.latest.engTotal.toFixed(1) : '---'}</div>
                                    <div className="mi__container__content__chart__details__box__divider"></div>
                                    <div className="mi__container__content__chart__details__box__year__changes">
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.yearBack?.engTotal, PMIData?.latest?.engTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.engTotal ? `${calculateChange(PMIData?.yearBack?.engTotal, PMIData?.latest?.engTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">1 YR RETURN</div>
                                        </div>
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.twoYearBack?.engTotal, PMIData?.latest?.engTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.engTotal ? `${calculateChange(PMIData?.twoYearBack?.engTotal, PMIData?.latest?.engTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">2 YR RETURN</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mi__container__content__chart__details">
                                <div className="mi__container__content__chart__heading">
                                    Japanese Market PMI 500 Index
                                </div>
                                <div className="mi__container__content__chart__details__box">
                                    <div className="mi__container__content__chart__details__box__title">As of {PMIData && PMIData.latest.date ? formatDate(PMIData.latest.date, "DD-MMM-YYYY") : "---"}</div>
                                    <div className="mi__container__content__chart__details__box__value">{PMIData && PMIData.latest.jpnTotal ? PMIData.latest.jpnTotal.toFixed(1) : '---'}</div>
                                    <div className="mi__container__content__chart__details__box__divider"></div>
                                    <div className="mi__container__content__chart__details__box__year__changes">
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.yearBack?.jpnTotal, PMIData?.latest?.jpnTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.jpnTotal ? `${calculateChange(PMIData?.yearBack?.jpnTotal, PMIData?.latest?.jpnTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">1 YR RETURN</div>
                                        </div>
                                        <div className="mi__container__content__chart__details__box__year__change">
                                            <div className="mi__container__content__chart__details__box__value2" style={{ color: calculateChange(PMIData?.twoYearBack?.jpnTotal, PMIData?.latest?.jpnTotal) < 0 ? "red" : "green" }}>{PMIData && PMIData?.latest?.jpnTotal ? `${calculateChange(PMIData?.twoYearBack?.jpnTotal, PMIData?.latest?.jpnTotal)}%` : "---"}</div>
                                            <div className="mi__container__content__chart__details__box__title">2 YR RETURN</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="mi__container__content__chart__info">
                        * PMI 500 Index CGC Gem Mint 10 (or Pristine 10) means the CGC 10 prices uses the Gem Mint 10  market prices (or Pristine 10) for graded 10 cards, it does not mean this index only contains CGC 10.
                    </div>
                    <div className="mi__container__content__chart__chart">
                        {selection === 0 && scalingFlag === 0 ? <MarketIndexChart data={graphData ? graphData : []} /> : null}
                        {selection === 0 && scalingFlag === 1 ? <MarketIndexChartScaled data={graphDataScaled ? graphDataScaled : []} /> : null}
                        {selection === 1 && scalingFlag === 0 ? <MarketIndexChartLang data={graphDataLang ? graphDataLang : []} /> : null}
                        {selection === 1 && scalingFlag === 1 ? <MarketIndexChartLangScaled data={graphDataLangScaled ? graphDataLangScaled : []} /> : null}
                    </div>
                    <div className="mi__container__content__chart__normalization">
                        {scalingFlag === 0 ?
                            <button className="mi__container__content__chart__normalization__info" onClick={() => setScalingFlag(1)}>
                                <div className="mi__container__content__chart__normalization__info__text">Data Normalization (Click Here)</div>
                                <div className="mi__container__content__chart__normalization__info__text">
                                    {selection === 0 ? `(CGC Index starts with the same value as the PMI 500 Index on 2021-Jan-01)`
                                        : `(Japanese/English Market Index starts with the same value as the PMI 500 Index on 2019-May-01)`
                                    }
                                </div>
                            </button>
                            :
                            <button className="mi__container__content__chart__normalization__info__selected" onClick={() => setScalingFlag(0)}>
                                <div className="mi__container__content__chart__normalization__info__text">Data Normalization (Click Here)</div>
                                <div className="mi__container__content__chart__normalization__info__text">
                                    {selection === 0 ? `(CGC Index starts with the same value as the PMI 500 Index on 2021-Jan-01)`
                                        : `(Japanese/English Market Index starts with the same value as the PMI 500 Index on 2019-May-01)`
                                    }
                                </div>
                            </button>
                        }
                    </div>
                    <div className="mi__container__content__chart__info">
                        *  All data available on TCGFISH.com, a professional data analysis website for collectible graded cards.
                    </div>
                </div>
                <div className="mi__container__content__characteristic__breakdown">
                    {selection === 1 ?
                        <div className="mi__container__content__chart__heading__selection">
                            <div className={langSelect === 0 ? "mi__container__content__chart__heading__selected" : "mi__container__content__chart__heading__select"} onClick={() => handleLangSelection(0)}>
                                English Card Index Details
                            </div>
                            <div className={langSelect === 1 ? "mi__container__content__chart__heading__selected" : "mi__container__content__chart__heading__select"} onClick={() => handleLangSelection(1)}>
                                Japanese Card Index Details
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="mi__container__content__list__heading">
                        {selection === 0 ? `PMI 500 Index Characteristics Breakdown`
                            : langSelect === 0 ? `English PMI 500 Index Characteristics Breakdown`
                                : `Japanese PMI 500 Index Characteristics Breakdown`
                        }
                    </div>
                    <div className="mi__container__content__characteristic__breakdown__section">
                        <div className="mi__container__content__characteristic__breakdown__section__box">
                            <div className="mi__container__content__characteristic__breakdown__section__box__heading">Card Release Year Distribution</div>
                            <div className="mi__container__content__characteristic__breakdown__section__box__list">
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">1995 - 1998</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.yearDistribution?.n5ton8 ? `${listingDetails?.yearDistribution?.n5ton8.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">1999 - 2003</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.yearDistribution?.n9tot3 ? `${listingDetails?.yearDistribution?.n9tot3.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">2004 - 2009</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.yearDistribution?.t4tot9 ? `${listingDetails?.yearDistribution?.t4tot9.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">2010 - 2016</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.yearDistribution?.t10tot16 ? `${listingDetails?.yearDistribution?.t10tot16.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">{`>= 2017`}</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.yearDistribution?.t17Up ? `${listingDetails?.yearDistribution?.t17Up.toFixed(2)}%` : "- - -"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mi__container__content__characteristic__breakdown__section__box">
                            <div className="mi__container__content__characteristic__breakdown__section__box__heading">
                                Grade Distribution
                            </div>
                            <div className="mi__container__content__characteristic__breakdown__section__box__list">
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">Grade 10</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.gradeDistribution?.grade10 ? `${listingDetails?.gradeDistribution?.grade10.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">Grade  9</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.gradeDistribution?.grade9 ? `${listingDetails?.gradeDistribution?.grade9.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">Grade  8</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.gradeDistribution?.grade8 ? `${listingDetails?.gradeDistribution?.grade8.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">Others</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.gradeDistribution?.gradeOthers ? `${listingDetails?.gradeDistribution?.gradeOthers.toFixed(2)}%` : "- - -"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mi__container__content__characteristic__breakdown__section__box">
                            <div className="mi__container__content__characteristic__breakdown__section__box__heading">Market Value Distribution</div>
                            <div className="mi__container__content__characteristic__breakdown__section__box__list">
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">{`<= $50`}</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.mktValueDistribution?.lt50 ? `${listingDetails?.mktValueDistribution?.lt50.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">{`>$50`}</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.mktValueDistribution?.lt150 ? `${listingDetails?.mktValueDistribution?.lt150.toFixed(2)}%` : "- - -"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mi__container__content__characteristic__breakdown__section__box">
                            <div className="mi__container__content__characteristic__breakdown__section__box__heading">Card Language Distribution</div>
                            <div className="mi__container__content__characteristic__breakdown__section__box__list">
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">English</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.languageDistribution?.English ? `${listingDetails?.languageDistribution?.English.toFixed(2)}%` : "- - -"}</div>
                                </div>
                                <div className="mi__container__content__characteristic__breakdown__section__box__list__item">
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__title">Japanese</div>
                                    <div className="mi__container__content__characteristic__breakdown__section__box__list__item__value">{listingDetails?.languageDistribution?.Japanese ? `${listingDetails?.languageDistribution?.Japanese.toFixed(2)}%` : "- - -"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mi__container__content__list">
                    <div className="mi__container__content__list__heading">
                        {selection === 0 ? `PMI 500 Index Constituents/Weights Allocation`
                            : langSelect === 0 ? `English PMI 500 Index Constituents/Weights Allocation`
                                : `Japanese PMI 500 Index Constituents/Weights Allocation`
                        }
                    </div>
                    <div className="mi__container__content__list__table">
                        <div className="mi__container__content__list__table__headings">
                            <div className="mi__container__content__list__table__heading" style={{ width: '48%' }}>Card Details</div>
                            <div className="mi__container__content__list__table__heading" style={{ width: '15%' }}>Grade</div>
                            <div className="mi__container__content__list__table__heading" style={{ width: '15%' }}>Portfolio Weight</div>
                            <div className="mi__container__content__list__table__heading" style={{ width: '15%' }}>Est. Market Value</div>
                        </div>
                        <div className="mi__container__content__list__table__rows">
                            {listingDetails && listingDetails.listings ?
                                listingDetails?.listings.map((item, index) => {
                                    return (
                                        <div className="mi__container__content__list__table__row">
                                            <div className="mi__container__content__list__table__row__item" style={{ width: '48%', textAlign: 'left' }}>{item.titles}</div>
                                            <div className="mi__container__content__list__table__row__item" style={{ width: '15%' }}>PSA {item.gradeBest}</div>
                                            <div className="mi__container__content__list__table__row__item" style={{ width: '15%' }}>{(item.weights * 100).toFixed(2)}%</div>
                                            <div className="mi__container__content__list__table__row__item" style={{ width: '15%' }}>${item.paDate.toFixed(2)}</div>
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarketIndex;
